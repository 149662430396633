*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

:root {
  --color-primary-0: #fdf5f9;
  --color-primary-1: #f7dae6;
  --color-primary-2: #f1bdd2;
  --color-primary-3: #ea90b2;
  --color-primary-4: #e24d81;
  --color-primary-5: #de3362;
  --color-primary-6: #b2274f;
  --color-primary-7: #8e1e3f;
  --color-primary-8: #62132b;
  --color-primary-9: #3e0a1c;
  --color-green-0: #e5fff3;
  --color-green-1: #ccffe6;
  --color-green-2: #99ffc7;
  --color-green-3: #66ffab;
  --color-green-4: #33ff92;
  --color-green-5: #00e676;
  --color-green-6: #00c263;
  --color-green-7: #00994e;
  --color-green-8: #006634;
  --color-green-9: #004d27;
  --color-cyan-0: #ebfdff;
  --color-cyan-1: #ccfaff;
  --color-cyan-2: #99f5ff;
  --color-cyan-3: #66efff;
  --color-cyan-4: #33eaff;
  --color-cyan-5: #00dcf5;
  --color-cyan-6: #00b7db;
  --color-cyan-7: #0095b2;
  --color-cyan-8: #00738a;
  --color-cyan-9: #004452;
  --color-red-0: #fceeeb;
  --color-red-1: #f8d5ce;
  --color-red-2: #f2ab9d;
  --color-red-3: #ee826d;
  --color-red-4: #ec5b41;
  --color-red-5: #e13c23;
  --color-red-6: #bc301b;
  --color-red-7: #8c2312;
  --color-red-8: #6f1c0c;
  --color-red-9: #501309;
  --color-yellow-0: #fff9e5;
  --color-yellow-1: #ffedb3;
  --color-yellow-2: #ffe799;
  --color-yellow-3: #ffdc66;
  --color-yellow-4: #ffd342;
  --color-yellow-5: #ffc400;
  --color-yellow-6: #cc9d00;
  --color-yellow-7: #a87e00;
  --color-yellow-8: #755c00;
  --color-yellow-9: #574300;
  --color-purple-0: #faf0ff;
  --color-purple-1: #dfc2ff;
  --color-purple-2: #ca99ff;
  --color-purple-3: #bd7aff;
  --color-purple-4: #b35cff;
  --color-purple-5: #a0f;
  --color-purple-6: #80c;
  --color-purple-7: #609;
  --color-purple-8: #4b0070;
  --color-purple-9: #3a0057;
  --color-cream-0: #fdfaf6;
  --color-cream-1: #f9efe2;
  --color-cream-2: #f1dcc0;
  --color-cream-3: #e1c199;
  --color-cream-4: #bc9c76;
  --color-cream-5: #ab8254;
  --color-cream-6: #896b43;
  --color-cream-7: #655034;
  --color-cream-8: #433523;
  --color-cream-9: #221b11;
  --color-danger-0: #fff0f0;
  --color-danger-1: #fcc;
  --color-danger-2: #ff8f8f;
  --color-danger-3: #f55;
  --color-danger-4: #ff2525;
  --color-danger-5: red;
  --color-danger-6: #c30000;
  --color-danger-7: #930000;
  --color-danger-8: #630000;
  --color-danger-9: #300;
  --color-neutral-0: #fafafa;
  --color-neutral-1: #eeedec;
  --color-neutral-2: #ceccca;
  --color-neutral-3: #b6b3af;
  --color-neutral-4: #9d9995;
  --color-neutral-5: #85807a;
  --color-neutral-6: #6f6b67;
  --color-neutral-7: #585451;
  --color-neutral-8: #42403d;
  --color-neutral-9: #1b1a18;
  --color-neutral-00: #fff;
  --background-body: var(--color-neutral-00);
  --background-html: #fff;
  --font-primary: "Space Grotesk";
  --font-secondary: "Inter";
  --font-primary-weight-bold: 700;
  --font-primary-weight-semibold: 600;
  --font-primary-weight-medium: 500;
  --font-primary-weight-regular: 400;
  --font-secondary-weight-bold: 700;
  --font-secondary-weight-semibold: 600;
  --font-secondary-weight-medium: 500;
  --font-secondary-weight-regular: 400;
  --font-family-heading: var(--font-primary);
  --font-color-heading: var(--color-primary-9);
  --typo-heading-display-fontsise: 96px;
  --typo-heading-display-lineheight: 112px;
  --typo-heading-display-fontweight: var(--font-primary-weight-bold);
  --typo-heading-h1-fontsize: 64px;
  --typo-heading-h1-lineheight: 72px;
  --typo-heading-h1-fontweight: var(--font-primary-weight-bold);
  --typo-heading-h2-fontsize: 48px;
  --typo-heading-h2-lineheight: 56px;
  --typo-heading-h2-fontweight: var(--font-primary-weight-bold);
  --typo-heading-h3-fontsize: 32px;
  --typo-heading-h3-lineheight: 40px;
  --typo-heading-h3-fontweight: var(--font-primary-weight-bold);
  --typo-heading-h4-fontsize: 24px;
  --typo-heading-h4-lineheight: 32px;
  --typo-heading-h4-fontweight: var(--font-primary-weight-bold);
  --typo-heading-h5-fontsize: 20px;
  --typo-heading-h5-lineheight: 24px;
  --typo-heading-h5-fontweight: var(--font-primary-weight-medium);
  --typo-heading-h6-fontsize: 16px;
  --typo-heading-h6-lineheight: 20px;
  --typo-heading-h6-fontweight: var(--font-primary-weight-medium);
  --font-family-body: var(--font-secondary);
  --font-color-body: var(--color-neutral-9);
  --typo-body-large-fontsize: 18px;
  --typo-body-large-lineheight: 28px;
  --typo-body-large-fontweight: var(--font-secondary-weight-regular);
  --typo-body-medium-fontsize: 16px;
  --typo-body-medium-lineheight: 24px;
  --typo-body-medium-fontweight: var(--font-secondary-weight-regular);
  --typo-body-small-fontsize: 14px;
  --typo-body-small-lineheight: 20px;
  --typo-body-small-fontweight: var(--font-secondary-weight-regular);
  --font-family-label: var(--font-secondary);
  --font-color-label: var(--color-neutral-9);
  --typo-label-medium-fontsize: 14px;
  --typo-label-medium-lineheight: 18px;
  --typo-label-medium-fontweight: var(--font-secondary-weight-medium);
  --typo-label-small-fontsize: 12px;
  --typo-label-small-lineheight: 14px;
  --typo-label-small-fontweight: var(--font-secondary-weight-medium);
  --font-family-caption: var(--font-secondary);
  --font-color-caption: var(--color-neutral-9);
  --typo-caption-large-fontsize: 14px;
  --typo-caption-large-lineheight: 20px;
  --typo-caption-large-fontweight: var(--font-secondary-weight-regular);
  --typo-caption-medium-fontsize: 12px;
  --typo-caption-medium-lineheight: 16px;
  --typo-caption-medium-fontweight: var(--font-secondary-weight-regular);
  --typo-caption-small-fontsize: 11px;
  --typo-caption-small-lineheight: 14px;
  --typo-caption-small-fontweight: var(--font-secondary-weight-regular);
  --font-family-button: var(--font-secondary);
  --font-color-button: var(--color-neutral-9);
  --typo-button-lg-fontsize: 18px;
  --typo-button-lg-lineheight: 24px;
  --typo-button-lg-fontweight: var(--font-secondary-weight-semibold);
  --typo-button-md-fontsize: 16px;
  --typo-button-md-lineheight: 24px;
  --typo-button-md-fontweight: var(--font-secondary-weight-semibold);
  --typo-button-sm-fontsize: 14px;
  --typo-button-sm-lineheight: 20px;
  --typo-button-sm-fontweight: var(--font-secondary-weight-semibold);
}

@media (width >= 375px) {
  .typo-heading-h6--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h6-fontsize);
    line-height: var(--typo-heading-h6-lineheight);
    font-weight: var(--typo-heading-h6-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h6--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h6-fontsize);
    line-height: var(--typo-heading-h6-lineheight);
    font-weight: var(--typo-heading-h6-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h6--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h6-fontsize);
    line-height: var(--typo-heading-h6-lineheight);
    font-weight: var(--typo-heading-h6-fontweight);
  }
}

@media (width >= 375px) {
  .typo-heading-h5--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h5-fontsize);
    line-height: var(--typo-heading-h5-lineheight);
    font-weight: var(--typo-heading-h5-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h5--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h5-fontsize);
    line-height: var(--typo-heading-h5-lineheight);
    font-weight: var(--typo-heading-h5-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h5--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h5-fontsize);
    line-height: var(--typo-heading-h5-lineheight);
    font-weight: var(--typo-heading-h5-fontweight);
  }
}

@media (width >= 375px) {
  .typo-heading-h4--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h4-fontsize);
    line-height: var(--typo-heading-h4-lineheight);
    font-weight: var(--typo-heading-h4-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h4--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h4-fontsize);
    line-height: var(--typo-heading-h4-lineheight);
    font-weight: var(--typo-heading-h4-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h4--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h4-fontsize);
    line-height: var(--typo-heading-h4-lineheight);
    font-weight: var(--typo-heading-h4-fontweight);
  }
}

@media (width >= 375px) {
  .typo-heading-h3--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h3-fontsize);
    line-height: var(--typo-heading-h3-lineheight);
    font-weight: var(--typo-heading-h3-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h3--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h3-fontsize);
    line-height: var(--typo-heading-h3-lineheight);
    font-weight: var(--typo-heading-h3-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h3--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h3-fontsize);
    line-height: var(--typo-heading-h3-lineheight);
    font-weight: var(--typo-heading-h3-fontweight);
  }
}

@media (width >= 375px) {
  .typo-heading-h2--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h2-fontsize);
    line-height: var(--typo-heading-h2-lineheight);
    font-weight: var(--typo-heading-h2-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h2--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h2-fontsize);
    line-height: var(--typo-heading-h2-lineheight);
    font-weight: var(--typo-heading-h2-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h2--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h2-fontsize);
    line-height: var(--typo-heading-h2-lineheight);
    font-weight: var(--typo-heading-h2-fontweight);
  }
}

@media (width >= 375px) {
  .typo-heading-h1--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h1-fontsize);
    line-height: var(--typo-heading-h1-lineheight);
    font-weight: var(--typo-heading-h1-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h1--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h1-fontsize);
    line-height: var(--typo-heading-h1-lineheight);
    font-weight: var(--typo-heading-h1-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h1--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h1-fontsize);
    line-height: var(--typo-heading-h1-lineheight);
    font-weight: var(--typo-heading-h1-fontweight);
  }
}

@media (width >= 375px) {
  .typo-heading-display--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-display-fontsize);
    line-height: var(--typo-heading-display-lineheight);
    font-weight: var(--typo-heading-display-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-display--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-display-fontsize);
    line-height: var(--typo-heading-display-lineheight);
    font-weight: var(--typo-heading-display-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-display--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-display-fontsize);
    line-height: var(--typo-heading-display-lineheight);
    font-weight: var(--typo-heading-display-fontweight);
  }
}

@media (width >= 375px) {
  .typo-body-sm--on-sm {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-small-fontsize);
    line-height: var(--typo-body-small-lineheight);
    font-weight: var(--typo-body-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-body-sm--on-md {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-small-fontsize);
    line-height: var(--typo-body-small-lineheight);
    font-weight: var(--typo-body-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-body-sm--on-lg {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-small-fontsize);
    line-height: var(--typo-body-small-lineheight);
    font-weight: var(--typo-body-small-fontweight);
  }
}

@media (width >= 375px) {
  .typo-body-md--on-sm {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-medium-fontsize);
    line-height: var(--typo-body-medium-lineheight);
    font-weight: var(--typo-body-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-body-md--on-md {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-medium-fontsize);
    line-height: var(--typo-body-medium-lineheight);
    font-weight: var(--typo-body-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-body-md--on-lg {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-medium-fontsize);
    line-height: var(--typo-body-medium-lineheight);
    font-weight: var(--typo-body-medium-fontweight);
  }
}

@media (width >= 375px) {
  .typo-body-lg--on-sm {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-large-fontsize);
    line-height: var(--typo-body-large-lineheight);
    font-weight: var(--typo-body-large-fontweight);
  }
}

@media (width >= 768px) {
  .typo-body-lg--on-md {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-large-fontsize);
    line-height: var(--typo-body-large-lineheight);
    font-weight: var(--typo-body-large-fontweight);
  }
}

@media (width >= 992px) {
  .typo-body-lg--on-lg {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-large-fontsize);
    line-height: var(--typo-body-large-lineheight);
    font-weight: var(--typo-body-large-fontweight);
  }
}

@media (width >= 375px) {
  .typo-label-sm--on-sm {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-small-fontsize);
    line-height: var(--typo-label-small-lineheight);
    font-weight: var(--typo-label-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-label-sm--on-md {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-small-fontsize);
    line-height: var(--typo-label-small-lineheight);
    font-weight: var(--typo-label-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-label-sm--on-lg {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-small-fontsize);
    line-height: var(--typo-label-small-lineheight);
    font-weight: var(--typo-label-small-fontweight);
  }
}

@media (width >= 375px) {
  .typo-label-md--on-sm {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-medium-fontsize);
    line-height: var(--typo-label-medium-lineheight);
    font-weight: var(--typo-label-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-label-md--on-md {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-medium-fontsize);
    line-height: var(--typo-label-medium-lineheight);
    font-weight: var(--typo-label-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-label-md--on-lg {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-medium-fontsize);
    line-height: var(--typo-label-medium-lineheight);
    font-weight: var(--typo-label-medium-fontweight);
  }
}

@media (width >= 375px) {
  .typo-caption-sm--on-sm {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-small-fontsize);
    line-height: var(--typo-caption-small-lineheight);
    font-weight: var(--typo-caption-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-caption-sm--on-md {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-small-fontsize);
    line-height: var(--typo-caption-small-lineheight);
    font-weight: var(--typo-caption-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-caption-sm--on-lg {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-small-fontsize);
    line-height: var(--typo-caption-small-lineheight);
    font-weight: var(--typo-caption-small-fontweight);
  }
}

@media (width >= 375px) {
  .typo-caption-md--on-sm {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-medium-fontsize);
    line-height: var(--typo-caption-medium-lineheight);
    font-weight: var(--typo-caption-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-caption-md--on-md {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-medium-fontsize);
    line-height: var(--typo-caption-medium-lineheight);
    font-weight: var(--typo-caption-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-caption-md--on-lg {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-medium-fontsize);
    line-height: var(--typo-caption-medium-lineheight);
    font-weight: var(--typo-caption-medium-fontweight);
  }
}

@media (width >= 375px) {
  .typo-caption-lg--on-sm {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-large-fontsize);
    line-height: var(--typo-caption-large-lineheight);
    font-weight: var(--typo-caption-large-fontweight);
  }
}

@media (width >= 768px) {
  .typo-caption-lg--on-md {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-large-fontsize);
    line-height: var(--typo-caption-large-lineheight);
    font-weight: var(--typo-caption-large-fontweight);
  }
}

@media (width >= 992px) {
  .typo-caption-lg--on-lg {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-large-fontsize);
    line-height: var(--typo-caption-large-lineheight);
    font-weight: var(--typo-caption-large-fontweight);
  }
}

@media (width >= 375px) {
  .typo-btn-sm--on-sm {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-small-fontsize);
    line-height: var(--typo-button-small-lineheight);
    font-weight: var(--typo-button-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-btn-sm--on-md {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-small-fontsize);
    line-height: var(--typo-button-small-lineheight);
    font-weight: var(--typo-button-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-btn-sm--on-lg {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-small-fontsize);
    line-height: var(--typo-button-small-lineheight);
    font-weight: var(--typo-button-small-fontweight);
  }
}

@media (width >= 375px) {
  .typo-btn-md--on-sm {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-medium-fontsize);
    line-height: var(--typo-button-medium-lineheight);
    font-weight: var(--typo-button-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-btn-md--on-md {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-medium-fontsize);
    line-height: var(--typo-button-medium-lineheight);
    font-weight: var(--typo-button-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-btn-md--on-lg {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-medium-fontsize);
    line-height: var(--typo-button-medium-lineheight);
    font-weight: var(--typo-button-medium-fontweight);
  }
}

@media (width >= 375px) {
  .typo-btn-lg--on-sm {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-large-fontsize);
    line-height: var(--typo-button-large-lineheight);
    font-weight: var(--typo-button-large-fontweight);
  }
}

@media (width >= 768px) {
  .typo-btn-lg--on-md {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-large-fontsize);
    line-height: var(--typo-button-large-lineheight);
    font-weight: var(--typo-button-large-fontweight);
  }
}

@media (width >= 992px) {
  .typo-btn-lg--on-lg {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-large-fontsize);
    line-height: var(--typo-button-large-lineheight);
    font-weight: var(--typo-button-large-fontweight);
  }
}

.mm-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pp-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.mm-1 {
  margin: 8px !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.pp-1 {
  padding: 2px;
}

.pt-1 {
  padding-top: 2px;
}

.pr-1 {
  padding-right: 2px;
}

.pb-1 {
  padding-bottom: 2px;
}

.pl-1 {
  padding-left: 2px;
}

.mm-2 {
  margin: 16px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.pp-2 {
  padding: 4px;
}

.pt-2 {
  padding-top: 4px;
}

.pr-2 {
  padding-right: 4px;
}

.pb-2 {
  padding-bottom: 4px;
}

.pl-2 {
  padding-left: 4px;
}

.mm-3 {
  margin: 24px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.pp-3 {
  padding: 6px;
}

.pt-3 {
  padding-top: 6px;
}

.pr-3 {
  padding-right: 6px;
}

.pb-3 {
  padding-bottom: 6px;
}

.pl-3 {
  padding-left: 6px;
}

.mm-4 {
  margin: 32px !important;
}

.mt-4 {
  margin-top: 32px !important;
}

.mr-4 {
  margin-right: 32px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.ml-4 {
  margin-left: 32px !important;
}

.pp-4 {
  padding: 8px;
}

.pt-4 {
  padding-top: 8px;
}

.pr-4 {
  padding-right: 8px;
}

.pb-4 {
  padding-bottom: 8px;
}

.pl-4 {
  padding-left: 8px;
}

.mm-5 {
  margin: 40px !important;
}

.mt-5 {
  margin-top: 40px !important;
}

.mr-5 {
  margin-right: 40px !important;
}

.mb-5 {
  margin-bottom: 40px !important;
}

.ml-5 {
  margin-left: 40px !important;
}

.pp-5 {
  padding: 10px;
}

.pt-5 {
  padding-top: 10px;
}

.pr-5 {
  padding-right: 10px;
}

.pb-5 {
  padding-bottom: 10px;
}

.pl-5 {
  padding-left: 10px;
}

.mm-6 {
  margin: 48px !important;
}

.mt-6 {
  margin-top: 48px !important;
}

.mr-6 {
  margin-right: 48px !important;
}

.mb-6 {
  margin-bottom: 48px !important;
}

.ml-6 {
  margin-left: 48px !important;
}

.pp-6 {
  padding: 12px;
}

.pt-6 {
  padding-top: 12px;
}

.pr-6 {
  padding-right: 12px;
}

.pb-6 {
  padding-bottom: 12px;
}

.pl-6 {
  padding-left: 12px;
}

.mm-7 {
  margin: 56px !important;
}

.mt-7 {
  margin-top: 56px !important;
}

.mr-7 {
  margin-right: 56px !important;
}

.mb-7 {
  margin-bottom: 56px !important;
}

.ml-7 {
  margin-left: 56px !important;
}

.pp-7 {
  padding: 14px;
}

.pt-7 {
  padding-top: 14px;
}

.pr-7 {
  padding-right: 14px;
}

.pb-7 {
  padding-bottom: 14px;
}

.pl-7 {
  padding-left: 14px;
}

.mm-8 {
  margin: 64px !important;
}

.mt-8 {
  margin-top: 64px !important;
}

.mr-8 {
  margin-right: 64px !important;
}

.mb-8 {
  margin-bottom: 64px !important;
}

.ml-8 {
  margin-left: 64px !important;
}

.pp-8 {
  padding: 16px;
}

.pt-8 {
  padding-top: 16px;
}

.pr-8 {
  padding-right: 16px;
}

.pb-8 {
  padding-bottom: 16px;
}

.pl-8 {
  padding-left: 16px;
}

:root {
  --btn-primary-idle-background: var(--color-primary-5);
  --btn-primary-idle-border: var(--color-primary-5);
  --btn-primary-idle-font: var(--color-neutral-00);
  --btn-primary-hover-background: var(--color-primary-6);
  --btn-primary-hover-border: var(--color-primary-6);
  --btn-primary-hover-font: var(--color-neutral-00);
  --btn-primary-active-background: var(--color-primary-7);
  --btn-primary-active-border: var(--color-primary-7);
  --btn-primary-active-font: var(--color-primary-1);
  --btn-primary-loading-background: var(--color-primary-5);
  --btn-primary-loading-border: var(--color-primary-5);
  --btn-primary-loading-font: var(--color-neutral-00);
  --btn-primary-disabled-background: var(--color-neutral-3);
  --btn-primary-disabled-border: var(--color-neutral-3);
  --btn-primary-disabled-font: var(--color-neutral-1);
  --btn-secondary-idle-background: var(--color-primary-1);
  --btn-secondary-idle-border: var(--color-primary-1);
  --btn-secondary-idle-font: var(--color-primary-7);
  --btn-secondary-hover-background: var(--color-primary-2);
  --btn-secondary-hover-border: var(--color-primary-2);
  --btn-secondary-hover-font: var(--color-primary-7);
  --btn-secondary-active-background: var(--color-primary-3);
  --btn-secondary-active-border: var(--color-primary-3);
  --btn-secondary-active-font: var(--color-primary-8);
  --btn-secondary-loading-background: var(--color-primary-1);
  --btn-secondary-loading-border: var(--color-primary-1);
  --btn-secondary-loading-font: var(--color-primary-7);
  --btn-secondary-disabled-background: var(--color-neutral-2);
  --btn-secondary-disabled-border: var(--color-neutral-2);
  --btn-secondary-disabled-font: var(--color-neutral-4);
  --btn-outline-idle-background: transparent;
  --btn-outline-idle-border: var(--color-primary-5);
  --btn-outline-idle-font: var(--color-primary-5);
  --btn-outline-hover-background: var(--color-primary-1);
  --btn-outline-hover-border: var(--color-primary-5);
  --btn-outline-hover-font: var(--color-primary-5);
  --btn-outline-active-background: var(--color-primary-2);
  --btn-outline-active-border: var(--color-primary-6);
  --btn-outline-active-font: var(--color-primary-6);
  --btn-outline-loading-background: var(--color-neutral-00);
  --btn-outline-loading-border: var(--color-primary-5);
  --btn-outline-loading-font: var(--color-primary-5);
  --btn-outline-disabled-background: var(--color-neutral-00);
  --btn-outline-disabled-border: var(--color-neutral-3);
  --btn-outline-disabled-font: var(--color-neutral-3);
  --btn-danger-idle-background: var(--color-danger-5);
  --btn-danger-idle-border: var(--color-danger-5);
  --btn-danger-idle-font: var(--color-neutral-00);
  --btn-danger-hover-background: var(--color-danger-6);
  --btn-danger-hover-border: var(--color-danger-6);
  --btn-danger-hover-font: var(--color-neutral-00);
  --btn-danger-active-background: var(--color-danger-7);
  --btn-danger-active-border: var(--color-danger-7);
  --btn-danger-active-font: var(--color-danger-1);
  --btn-danger-loading-background: var(--color-danger-5);
  --btn-danger-loading-border: var(--color-danger-5);
  --btn-danger-loading-font: var(--color-neutral-00);
  --btn-danger-disabled-background: var(--color-neutral-3);
  --btn-danger-disabled-border: var(--color-neutral-3);
  --btn-danger-disabled-font: var(--color-neutral-1);
  --btn-border-radius: 8px;
  --btn-icon-sm: 16px;
  --btn-icon-md: 24px;
  --btn-icon-lg: 24px;
  --btn-icon-margin: 8px;
  --btn-padding-sm: 10px 16px;
  --btn-padding-md: 16px 20px;
  --btn-padding-lg: 20px 24px;
}

.btn, .btn-icon {
  border-radius: var(--btn-border-radius);
  text-align: center;
  background: none;
  border: 1px solid;
  outline: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.btn--sm {
  padding: var(--btn-padding-sm);
  font-size: var(--typo-button-sm-fontsize);
  line-height: var(--typo-button-sm-lineheight);
  font-weight: var(--typo-button-sm-fontweight);
}

@media (width >= 375px) {
  .btn--sm-on-sm {
    padding: var(--btn-padding-sm);
    font-size: var(--typo-button-sm-fontsize);
    line-height: var(--typo-button-sm-lineheight);
    font-weight: var(--typo-button-sm-fontweight);
  }
}

@media (width >= 768px) {
  .btn--sm-on-md {
    padding: var(--btn-padding-sm);
    font-size: var(--typo-button-sm-fontsize);
    line-height: var(--typo-button-sm-lineheight);
    font-weight: var(--typo-button-sm-fontweight);
  }
}

@media (width >= 992px) {
  .btn--sm-on-lg {
    padding: var(--btn-padding-sm);
    font-size: var(--typo-button-sm-fontsize);
    line-height: var(--typo-button-sm-lineheight);
    font-weight: var(--typo-button-sm-fontweight);
  }
}

.btn--md {
  padding: var(--btn-padding-md);
  font-size: var(--typo-button-md-fontsize);
  line-height: var(--typo-button-md-lineheight);
  font-weight: var(--typo-button-md-fontweight);
}

@media (width >= 375px) {
  .btn--md-on-sm {
    padding: var(--btn-padding-md);
    font-size: var(--typo-button-md-fontsize);
    line-height: var(--typo-button-md-lineheight);
    font-weight: var(--typo-button-md-fontweight);
  }
}

@media (width >= 768px) {
  .btn--md-on-md {
    padding: var(--btn-padding-md);
    font-size: var(--typo-button-md-fontsize);
    line-height: var(--typo-button-md-lineheight);
    font-weight: var(--typo-button-md-fontweight);
  }
}

@media (width >= 992px) {
  .btn--md-on-lg {
    padding: var(--btn-padding-md);
    font-size: var(--typo-button-md-fontsize);
    line-height: var(--typo-button-md-lineheight);
    font-weight: var(--typo-button-md-fontweight);
  }
}

.btn--lg {
  padding: var(--btn-padding-lg);
  font-size: var(--typo-button-lg-fontsize);
  line-height: var(--typo-button-lg-lineheight);
  font-weight: var(--typo-button-lg-fontweight);
}

@media (width >= 375px) {
  .btn--lg-on-sm {
    padding: var(--btn-padding-lg);
    font-size: var(--typo-button-lg-fontsize);
    line-height: var(--typo-button-lg-lineheight);
    font-weight: var(--typo-button-lg-fontweight);
  }
}

@media (width >= 768px) {
  .btn--lg-on-md {
    padding: var(--btn-padding-lg);
    font-size: var(--typo-button-lg-fontsize);
    line-height: var(--typo-button-lg-lineheight);
    font-weight: var(--typo-button-lg-fontweight);
  }
}

@media (width >= 992px) {
  .btn--lg-on-lg {
    padding: var(--btn-padding-lg);
    font-size: var(--typo-button-lg-fontsize);
    line-height: var(--typo-button-lg-lineheight);
    font-weight: var(--typo-button-lg-fontweight);
  }
}

.btn--full {
  width: 100%;
}

@media (width >= 375px) {
  .btn--full-on-sm {
    width: 100%;
  }
}

@media (width >= 768px) {
  .btn--full-on-md {
    width: 100%;
  }
}

@media (width >= 992px) {
  .btn--full-on-lg {
    width: 100%;
  }
}

.btn--fit {
  width: auto;
}

@media (width >= 375px) {
  .btn--fit-on-sm {
    width: auto;
  }
}

@media (width >= 768px) {
  .btn--fit-on-md {
    width: auto;
  }
}

@media (width >= 992px) {
  .btn--fit-on-lg {
    width: auto;
  }
}

.btn img, .btn-icon img {
  vertical-align: text-top;
  display: inline;
}

.btn-icon svg {
  vertical-align: middle;
  width: var(--btn-icon-sm);
  height: var(--btn-icon-sm);
}

.btn-icon.btn--sm svg, .btn-icon.btn--sm-on-sm svg, .btn-icon.btn--sm-on-md svg, .btn-icon.btn--sm-on-lg svg {
  width: var(--btn-icon-sm);
  height: var(--btn-icon-sm);
}

.btn-icon.btn--md svg, .btn-icon.btn--md-on-sm svg, .btn-icon.btn--md-on-md svg, .btn-icon.btn--md-on-lg svg {
  width: var(--btn-icon-md);
  height: var(--btn-icon-md);
}

.btn-icon.btn--lg svg, .btn-icon.btn--lg-on-sm svg, .btn-icon.btn--lg-on-md svg, .btn-icon.btn--lg-on-lg svg {
  width: var(--btn-icon-lg);
  height: var(--btn-icon-lg);
}

.btn-primary {
  background: var(--btn-primary-idle-background);
  border-color: var(--btn-primary-idle-border);
  color: var(--btn-primary-idle-font);
}

.btn-primary:hover {
  background: var(--btn-primary-hover-background);
  border-color: var(--btn-primary-hover-border);
  color: var(--btn-primary-hover-font);
}

.btn-primary:active {
  background: var(--btn-primary-active-background);
  border-color: var(--btn-primary-active-border);
  color: var(--btn-primary-active-font);
}

.btn-primary:disabled {
  background: var(--btn-primary-disabled-background);
  border-color: var(--btn-primary-disabled-border);
  color: var(--btn-primary-disabled-font);
}

.btn-primary.loading {
  background: var(--btn-primary-loading-background);
  border-color: var(--btn-primary-loading-border);
  color: var(--btn-primary-loading-font);
}

.btn-secondary {
  background: var(--btn-secondary-idle-background);
  border-color: var(--btn-secondary-idle-border);
  color: var(--btn-secondary-idle-font);
}

.btn-secondary:hover {
  background: var(--btn-secondary-hover-background);
  border-color: var(--btn-secondary-hover-border);
  color: var(--btn-secondary-hover-font);
}

.btn-secondary:active {
  background: var(--btn-secondary-active-background);
  border-color: var(--btn-secondary-active-border);
  color: var(--btn-secondary-active-font);
}

.btn-secondary:disabled {
  background: var(--btn-secondary-disabled-background);
  border-color: var(--btn-secondary-disabled-border);
  color: var(--btn-secondary-disabled-font);
}

.btn-secondary.loading {
  background: var(--btn-secondary-loading-background);
  border-color: var(--btn-secondary-loading-border);
  color: var(--btn-secondary-loading-font);
}

.btn-outline {
  background: var(--btn-outline-idle-background);
  border-color: var(--btn-outline-idle-border);
  color: var(--btn-outline-idle-font);
}

.btn-outline:hover {
  background: var(--btn-outline-hover-background);
  border-color: var(--btn-outline-hover-border);
  color: var(--btn-outline-hover-font);
}

.btn-outline:active {
  background: var(--btn-outline-active-background);
  border-color: var(--btn-outline-active-border);
  color: var(--btn-outline-active-font);
}

.btn-outline:disabled {
  background: var(--btn-outline-disabled-background);
  border-color: var(--btn-outline-disabled-border);
  color: var(--btn-outline-disabled-font);
}

.btn-outline.loading {
  background: var(--btn-outline-loading-background);
  border-color: var(--btn-outline-loading-border);
  color: var(--btn-outline-loading-font);
}

.btn-danger {
  background: var(--btn-danger-idle-background);
  border-color: var(--btn-danger-idle-border);
  color: var(--btn-danger-idle-font);
}

.btn-danger:hover {
  background: var(--btn-danger-hover-background);
  border-color: var(--btn-danger-hover-border);
  color: var(--btn-danger-hover-font);
}

.btn-danger:active {
  background: var(--btn-danger-active-background);
  border-color: var(--btn-danger-active-border);
  color: var(--btn-danger-active-font);
}

.btn-danger:disabled {
  background: var(--btn-danger-disabled-background);
  border-color: var(--btn-danger-disabled-border);
  color: var(--btn-danger-disabled-font);
}

.btn-danger.loading {
  background: var(--btn-danger-loading-background);
  border-color: var(--btn-danger-loading-border);
  color: var(--btn-danger-loading-font);
}

.typo-heading-h6 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h6-fontsize);
  line-height: var(--typo-heading-h6-lineheight);
  font-weight: var(--typo-heading-h6-fontweight);
}

@media (width >= 375px) {
  .typo-heading-h6--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h6-fontsize);
    line-height: var(--typo-heading-h6-lineheight);
    font-weight: var(--typo-heading-h6-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h6--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h6-fontsize);
    line-height: var(--typo-heading-h6-lineheight);
    font-weight: var(--typo-heading-h6-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h6--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h6-fontsize);
    line-height: var(--typo-heading-h6-lineheight);
    font-weight: var(--typo-heading-h6-fontweight);
  }
}

.typo-heading-h5 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h5-fontsize);
  line-height: var(--typo-heading-h5-lineheight);
  font-weight: var(--typo-heading-h5-fontweight);
}

@media (width >= 375px) {
  .typo-heading-h5--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h5-fontsize);
    line-height: var(--typo-heading-h5-lineheight);
    font-weight: var(--typo-heading-h5-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h5--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h5-fontsize);
    line-height: var(--typo-heading-h5-lineheight);
    font-weight: var(--typo-heading-h5-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h5--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h5-fontsize);
    line-height: var(--typo-heading-h5-lineheight);
    font-weight: var(--typo-heading-h5-fontweight);
  }
}

.typo-heading-h4 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h4-fontsize);
  line-height: var(--typo-heading-h4-lineheight);
  font-weight: var(--typo-heading-h4-fontweight);
}

@media (width >= 375px) {
  .typo-heading-h4--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h4-fontsize);
    line-height: var(--typo-heading-h4-lineheight);
    font-weight: var(--typo-heading-h4-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h4--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h4-fontsize);
    line-height: var(--typo-heading-h4-lineheight);
    font-weight: var(--typo-heading-h4-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h4--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h4-fontsize);
    line-height: var(--typo-heading-h4-lineheight);
    font-weight: var(--typo-heading-h4-fontweight);
  }
}

.typo-heading-h3 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h3-fontsize);
  line-height: var(--typo-heading-h3-lineheight);
  font-weight: var(--typo-heading-h3-fontweight);
}

@media (width >= 375px) {
  .typo-heading-h3--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h3-fontsize);
    line-height: var(--typo-heading-h3-lineheight);
    font-weight: var(--typo-heading-h3-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h3--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h3-fontsize);
    line-height: var(--typo-heading-h3-lineheight);
    font-weight: var(--typo-heading-h3-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h3--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h3-fontsize);
    line-height: var(--typo-heading-h3-lineheight);
    font-weight: var(--typo-heading-h3-fontweight);
  }
}

.typo-heading-h2 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h2-fontsize);
  line-height: var(--typo-heading-h2-lineheight);
  font-weight: var(--typo-heading-h2-fontweight);
}

@media (width >= 375px) {
  .typo-heading-h2--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h2-fontsize);
    line-height: var(--typo-heading-h2-lineheight);
    font-weight: var(--typo-heading-h2-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h2--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h2-fontsize);
    line-height: var(--typo-heading-h2-lineheight);
    font-weight: var(--typo-heading-h2-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h2--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h2-fontsize);
    line-height: var(--typo-heading-h2-lineheight);
    font-weight: var(--typo-heading-h2-fontweight);
  }
}

.typo-heading-h1 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h1-fontsize);
  line-height: var(--typo-heading-h1-lineheight);
  font-weight: var(--typo-heading-h1-fontweight);
}

@media (width >= 375px) {
  .typo-heading-h1--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h1-fontsize);
    line-height: var(--typo-heading-h1-lineheight);
    font-weight: var(--typo-heading-h1-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-h1--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h1-fontsize);
    line-height: var(--typo-heading-h1-lineheight);
    font-weight: var(--typo-heading-h1-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-h1--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-h1-fontsize);
    line-height: var(--typo-heading-h1-lineheight);
    font-weight: var(--typo-heading-h1-fontweight);
  }
}

.typo-heading-display {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-display-fontsize);
  line-height: var(--typo-heading-display-lineheight);
  font-weight: var(--typo-heading-display-fontweight);
}

@media (width >= 375px) {
  .typo-heading-display--on-sm {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-display-fontsize);
    line-height: var(--typo-heading-display-lineheight);
    font-weight: var(--typo-heading-display-fontweight);
  }
}

@media (width >= 768px) {
  .typo-heading-display--on-md {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-display-fontsize);
    line-height: var(--typo-heading-display-lineheight);
    font-weight: var(--typo-heading-display-fontweight);
  }
}

@media (width >= 992px) {
  .typo-heading-display--on-lg {
    color: var(--font-color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--typo-heading-display-fontsize);
    line-height: var(--typo-heading-display-lineheight);
    font-weight: var(--typo-heading-display-fontweight);
  }
}

.typo-body-sm {
  color: var(--font-color-body);
  font-family: var(--font-family-body);
  font-size: var(--typo-body-small-fontsize);
  line-height: var(--typo-body-small-lineheight);
  font-weight: var(--typo-body-small-fontweight);
}

@media (width >= 375px) {
  .typo-body-sm--on-sm {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-small-fontsize);
    line-height: var(--typo-body-small-lineheight);
    font-weight: var(--typo-body-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-body-sm--on-md {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-small-fontsize);
    line-height: var(--typo-body-small-lineheight);
    font-weight: var(--typo-body-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-body-sm--on-lg {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-small-fontsize);
    line-height: var(--typo-body-small-lineheight);
    font-weight: var(--typo-body-small-fontweight);
  }
}

.typo-body-md {
  color: var(--font-color-body);
  font-family: var(--font-family-body);
  font-size: var(--typo-body-medium-fontsize);
  line-height: var(--typo-body-medium-lineheight);
  font-weight: var(--typo-body-medium-fontweight);
}

@media (width >= 375px) {
  .typo-body-md--on-sm {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-medium-fontsize);
    line-height: var(--typo-body-medium-lineheight);
    font-weight: var(--typo-body-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-body-md--on-md {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-medium-fontsize);
    line-height: var(--typo-body-medium-lineheight);
    font-weight: var(--typo-body-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-body-md--on-lg {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-medium-fontsize);
    line-height: var(--typo-body-medium-lineheight);
    font-weight: var(--typo-body-medium-fontweight);
  }
}

.typo-body-lg {
  color: var(--font-color-body);
  font-family: var(--font-family-body);
  font-size: var(--typo-body-large-fontsize);
  line-height: var(--typo-body-large-lineheight);
  font-weight: var(--typo-body-large-fontweight);
}

@media (width >= 375px) {
  .typo-body-lg--on-sm {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-large-fontsize);
    line-height: var(--typo-body-large-lineheight);
    font-weight: var(--typo-body-large-fontweight);
  }
}

@media (width >= 768px) {
  .typo-body-lg--on-md {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-large-fontsize);
    line-height: var(--typo-body-large-lineheight);
    font-weight: var(--typo-body-large-fontweight);
  }
}

@media (width >= 992px) {
  .typo-body-lg--on-lg {
    color: var(--font-color-body);
    font-family: var(--font-family-body);
    font-size: var(--typo-body-large-fontsize);
    line-height: var(--typo-body-large-lineheight);
    font-weight: var(--typo-body-large-fontweight);
  }
}

.typo-label-sm {
  color: var(--font-color-label);
  font-family: var(--font-family-label);
  font-size: var(--typo-label-small-fontsize);
  line-height: var(--typo-label-small-lineheight);
  font-weight: var(--typo-label-small-fontweight);
}

@media (width >= 375px) {
  .typo-label-sm--on-sm {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-small-fontsize);
    line-height: var(--typo-label-small-lineheight);
    font-weight: var(--typo-label-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-label-sm--on-md {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-small-fontsize);
    line-height: var(--typo-label-small-lineheight);
    font-weight: var(--typo-label-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-label-sm--on-lg {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-small-fontsize);
    line-height: var(--typo-label-small-lineheight);
    font-weight: var(--typo-label-small-fontweight);
  }
}

.typo-label-md {
  color: var(--font-color-label);
  font-family: var(--font-family-label);
  font-size: var(--typo-label-medium-fontsize);
  line-height: var(--typo-label-medium-lineheight);
  font-weight: var(--typo-label-medium-fontweight);
}

@media (width >= 375px) {
  .typo-label-md--on-sm {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-medium-fontsize);
    line-height: var(--typo-label-medium-lineheight);
    font-weight: var(--typo-label-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-label-md--on-md {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-medium-fontsize);
    line-height: var(--typo-label-medium-lineheight);
    font-weight: var(--typo-label-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-label-md--on-lg {
    color: var(--font-color-label);
    font-family: var(--font-family-label);
    font-size: var(--typo-label-medium-fontsize);
    line-height: var(--typo-label-medium-lineheight);
    font-weight: var(--typo-label-medium-fontweight);
  }
}

.typo-caption-sm {
  color: var(--font-color-caption);
  font-family: var(--font-family-caption);
  font-size: var(--typo-caption-small-fontsize);
  line-height: var(--typo-caption-small-lineheight);
  font-weight: var(--typo-caption-small-fontweight);
}

@media (width >= 375px) {
  .typo-caption-sm--on-sm {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-small-fontsize);
    line-height: var(--typo-caption-small-lineheight);
    font-weight: var(--typo-caption-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-caption-sm--on-md {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-small-fontsize);
    line-height: var(--typo-caption-small-lineheight);
    font-weight: var(--typo-caption-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-caption-sm--on-lg {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-small-fontsize);
    line-height: var(--typo-caption-small-lineheight);
    font-weight: var(--typo-caption-small-fontweight);
  }
}

.typo-caption-md {
  color: var(--font-color-caption);
  font-family: var(--font-family-caption);
  font-size: var(--typo-caption-medium-fontsize);
  line-height: var(--typo-caption-medium-lineheight);
  font-weight: var(--typo-caption-medium-fontweight);
}

@media (width >= 375px) {
  .typo-caption-md--on-sm {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-medium-fontsize);
    line-height: var(--typo-caption-medium-lineheight);
    font-weight: var(--typo-caption-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-caption-md--on-md {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-medium-fontsize);
    line-height: var(--typo-caption-medium-lineheight);
    font-weight: var(--typo-caption-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-caption-md--on-lg {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-medium-fontsize);
    line-height: var(--typo-caption-medium-lineheight);
    font-weight: var(--typo-caption-medium-fontweight);
  }
}

.typo-caption-lg {
  color: var(--font-color-caption);
  font-family: var(--font-family-caption);
  font-size: var(--typo-caption-large-fontsize);
  line-height: var(--typo-caption-large-lineheight);
  font-weight: var(--typo-caption-large-fontweight);
}

@media (width >= 375px) {
  .typo-caption-lg--on-sm {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-large-fontsize);
    line-height: var(--typo-caption-large-lineheight);
    font-weight: var(--typo-caption-large-fontweight);
  }
}

@media (width >= 768px) {
  .typo-caption-lg--on-md {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-large-fontsize);
    line-height: var(--typo-caption-large-lineheight);
    font-weight: var(--typo-caption-large-fontweight);
  }
}

@media (width >= 992px) {
  .typo-caption-lg--on-lg {
    color: var(--font-color-caption);
    font-family: var(--font-family-caption);
    font-size: var(--typo-caption-large-fontsize);
    line-height: var(--typo-caption-large-lineheight);
    font-weight: var(--typo-caption-large-fontweight);
  }
}

.typo-btn-sm {
  color: var(--font-color-button);
  font-family: var(--font-family-button);
  font-size: var(--typo-button-small-fontsize);
  line-height: var(--typo-button-small-lineheight);
  font-weight: var(--typo-button-small-fontweight);
}

@media (width >= 375px) {
  .typo-btn-sm--on-sm {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-small-fontsize);
    line-height: var(--typo-button-small-lineheight);
    font-weight: var(--typo-button-small-fontweight);
  }
}

@media (width >= 768px) {
  .typo-btn-sm--on-md {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-small-fontsize);
    line-height: var(--typo-button-small-lineheight);
    font-weight: var(--typo-button-small-fontweight);
  }
}

@media (width >= 992px) {
  .typo-btn-sm--on-lg {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-small-fontsize);
    line-height: var(--typo-button-small-lineheight);
    font-weight: var(--typo-button-small-fontweight);
  }
}

.typo-btn-md {
  color: var(--font-color-button);
  font-family: var(--font-family-button);
  font-size: var(--typo-button-medium-fontsize);
  line-height: var(--typo-button-medium-lineheight);
  font-weight: var(--typo-button-medium-fontweight);
}

@media (width >= 375px) {
  .typo-btn-md--on-sm {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-medium-fontsize);
    line-height: var(--typo-button-medium-lineheight);
    font-weight: var(--typo-button-medium-fontweight);
  }
}

@media (width >= 768px) {
  .typo-btn-md--on-md {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-medium-fontsize);
    line-height: var(--typo-button-medium-lineheight);
    font-weight: var(--typo-button-medium-fontweight);
  }
}

@media (width >= 992px) {
  .typo-btn-md--on-lg {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-medium-fontsize);
    line-height: var(--typo-button-medium-lineheight);
    font-weight: var(--typo-button-medium-fontweight);
  }
}

.typo-btn-lg {
  color: var(--font-color-button);
  font-family: var(--font-family-button);
  font-size: var(--typo-button-large-fontsize);
  line-height: var(--typo-button-large-lineheight);
  font-weight: var(--typo-button-large-fontweight);
}

@media (width >= 375px) {
  .typo-btn-lg--on-sm {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-large-fontsize);
    line-height: var(--typo-button-large-lineheight);
    font-weight: var(--typo-button-large-fontweight);
  }
}

@media (width >= 768px) {
  .typo-btn-lg--on-md {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-large-fontsize);
    line-height: var(--typo-button-large-lineheight);
    font-weight: var(--typo-button-large-fontweight);
  }
}

@media (width >= 992px) {
  .typo-btn-lg--on-lg {
    color: var(--font-color-button);
    font-family: var(--font-family-button);
    font-size: var(--typo-button-large-fontsize);
    line-height: var(--typo-button-large-lineheight);
    font-weight: var(--typo-button-large-fontweight);
  }
}

.typo-hightlight {
  color: var(--color-primary-5);
}

html {
  background-color: var(--background-html);
  scroll-behavior: smooth;
}

body {
  color: var(--font-color-body);
  font-family: var(--font-family-body);
  font-size: var(--typo-body-medium-fontsize);
  line-height: var(--typo-body-medium-lineheight);
  font-weight: var(--typo-body-medium-fontweight);
  background: var(--background-body);
}

h1 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-1-fontsize);
  line-height: var(--typo-heading-1-lineheight);
  font-weight: var(--typo-heading-1-fontweight);
}

h2 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-2-fontsize);
  line-height: var(--typo-heading-2-lineheight);
  font-weight: var(--typo-heading-2-fontweight);
}

h3 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-3-fontsize);
  line-height: var(--typo-heading-3-lineheight);
  font-weight: var(--typo-heading-3-fontweight);
}

h4 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-4-fontsize);
  line-height: var(--typo-heading-4-lineheight);
  font-weight: var(--typo-heading-4-fontweight);
}

h5 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-5-fontsize);
  line-height: var(--typo-heading-5-lineheight);
  font-weight: var(--typo-heading-5-fontweight);
}

h6 {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-6-fontsize);
  line-height: var(--typo-heading-6-lineheight);
  font-weight: var(--typo-heading-6-fontweight);
}

p {
  color: var(--font-color-body);
  font-family: var(--font-family-body);
  font-size: var(--typo-body-medium-fontsize);
  line-height: var(--typo-body-medium-lineheight);
  font-weight: var(--typo-body-medium-fontweight);
}

a {
  color: var(--color-primary-5);
  text-decoration: none;
  transition: color .2s ease-in-out;
}

a:hover {
  color: var(--color-primary-8);
}

.page-header {
  background-color: var(--background-html);
  padding-top: 8px;
}

.page-header h1, .page-header h2, .page-header h3, .page-header h4, .page-header h5, .page-header h6 {
  margin-bottom: 8px;
}

.page-header__nav {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.page-header__mmenu {
  flex-direction: row-reverse;
  height: 42px;
  display: inline-flex;
  position: relative;
}

@media (width >= 992px) {
  .page-header__mmenu {
    display: none;
  }
}

.page-header__mmenu ul {
  margin-right: 24px;
  display: none;
}

.page-header__mmenu ul li {
  margin-right: 12px;
}

.page-header__mmenu ul li:last-child {
  margin-right: 0;
}

.page-header__mmenu > img, .page-header__mmenu-hack {
  width: 24px;
}

.page-header__mmenu-hack {
  opacity: 0;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.page-header__mmenu-hack:checked ~ ul {
  display: inline-flex;
}

.page-header__menu {
  display: none;
}

@media (width >= 992px) {
  .page-header__menu {
    display: block;
  }
}

.page-wrapper {
  margin: 0 auto;
  padding: 24px 0;
}

@media (width >= 375px) {
  .page-wrapper {
    padding: 24px;
  }
}

@media (width >= 768px) {
  .page-wrapper {
    padding: 24px 40px;
  }
}

@media (width >= 992px) {
  .page-wrapper {
    max-width: 1200px;
    padding: 24px 48px;
  }
}

@media (width >= 1200px) {
  .page-wrapper {
    max-width: 1200px;
    padding: 24px 80px;
  }
}

.page-content p {
  margin-bottom: 16px;
}

.page-footer {
  background: var(--color-primary-8);
}

.page-footer a:not(.btn):not(.btn-icon) {
  color: var(--color-primary-2);
}

.page-footer__cta {
  background: var(--color-primary-1);
  padding: 0;
  color: var(--color-neutral-8) !important;
}

.page-footer__cta h1, .page-footer__cta h2, .page-footer__cta h3, .page-footer__cta h4, .page-footer__cta h5, .page-footer__cta h6 {
  margin-bottom: 12px;
}

.page-footer__cta p {
  margin-bottom: 24px;
}

@media (width >= 768px) {
  .page-footer__cta .btn, .page-footer__cta .btn-icon {
    margin-left: 40px;
  }
}

.page-footer__cta .page-wrapper {
  padding: 80px 16px;
}

@media (width >= 768px) {
  .page-footer__cta .page-wrapper {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
}

@media (width >= 992px) {
  .page-footer__cta .page-wrapper {
    padding: 80px 48px;
  }
}

.page-footer__links {
  text-align: center;
  padding: 48px 0;
}

@media (width >= 992px) {
  .page-footer__links {
    padding: 80px 0;
  }
}

.page-footer__links img {
  margin-bottom: 40px;
  display: inline-block;
}

@media (width >= 768px) {
  .page-footer__links img {
    margin-bottom: 0;
  }

  .page-footer__links .page-wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.ul-h {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.ul-h li {
  margin-right: 48px;
}

.ul-h li:last-child {
  margin-right: 0;
}

.page-legal .page-content h2, .page-legal .page-content h3 {
  margin-bottom: 8px;
}

@keyframes home-background-r {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes home-background-r2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0);
  }
}

.home-hero {
  padding: 0;
  overflow-y: hidden;
}

@media (width >= 992px) {
  .home-hero {
    padding: 0 0 168px;
  }
}

.home-hero .page-wrapper {
  padding: 32px 24px 64px;
  position: relative;
}

@media (width >= 768px) {
  .home-hero .page-wrapper {
    padding: 64px 40px;
  }
}

@media (width >= 992px) {
  .home-hero .page-wrapper {
    padding: 64px 48px;
  }
}

@media (width >= 768px) {
  .home-hero .page-header__nav {
    margin-bottom: 40px;
  }
}

@media (width >= 992px) {
  .home-hero .page-header__nav {
    margin-bottom: 80px;
  }
}

.home-hero .page-header__nav .page-header__logo img {
  height: 24px;
}

.home-hero h1 {
  margin-bottom: 16px;
}

@media (width >= 375px) {
  .home-hero h1 {
    width: 310px;
  }
}

@media (width >= 768px) {
  .home-hero h1 {
    width: 552px;
  }
}

@media (width >= 992px) {
  .home-hero h1 {
    width: 442px;
    margin-bottom: 24px;
  }
}

.home-hero p {
  max-width: 390px;
  margin-bottom: 24px;
}

@media (width >= 768px) {
  .home-hero p {
    width: 480px;
  }
}

@media (width >= 992px) {
  .home-hero p {
    width: auto;
    max-width: 468px;
    margin-bottom: 40px;
  }
}

.home-hero__cta {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

@media (width >= 768px) {
  .home-hero__cta {
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
  }
}

.home-hero__background {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (width >= 992px) {
  .home-hero__background {
    display: block;
  }
}

.home-hero__background > div {
  align-items: flex-start;
  gap: 32px;
  display: flex;
  overflow-y: hidden;
}

.home-hero__background-r, .home-hero__background-r2 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  display: flex;
}

.home-hero__background-r > div, .home-hero__background-r2 > div {
  border-radius: 32px;
}

.home-hero__background-r svg, .home-hero__background-r2 svg {
  fill: #000;
  width: 32px;
  margin: 32px;
}

.home-hero__background-r {
  animation: 20s linear infinite home-background-r;
}

.home-hero__background-r2 {
  animation: 40s linear infinite home-background-r2;
}

.home-hero__background-ri {
  background: var(--color-primary-5);
  width: 298px;
  height: 466px;
}

.home-hero__background-ri svg {
  fill: var(--color-primary-6);
}

.home-hero__background-ri:nth-child(2) {
  background: var(--color-yellow-5);
}

.home-hero__background-ri:nth-child(2) svg {
  fill: var(--color-yellow-6);
}

.home-hero__background-ri:nth-child(3) {
  background: var(--color-green-5);
}

.home-hero__background-ri:nth-child(3) svg {
  fill: var(--color-green-6);
}

.home-hero__background-ri:nth-child(4) {
  background: var(--color-cyan-5);
}

.home-hero__background-ri:nth-child(4) svg {
  fill: var(--color-cyan-6);
}

.home-hero__background-ri:nth-child(5) {
  background: var(--color-purple-5);
}

.home-hero__background-ri:nth-child(5) svg {
  fill: var(--color-purple-6);
}

.home-hero__background-r2i {
  background: var(--color-green-5);
  width: 298px;
  height: 308px;
}

.home-hero__background-r2i svg {
  fill: var(--color-green-6);
}

.home-hero__background-r2i:nth-child(2) {
  background: var(--color-cyan-5);
}

.home-hero__background-r2i:nth-child(2) svg {
  fill: var(--color-cyan-6);
}

.home-hero__background-r2i:nth-child(3) {
  background: var(--color-purple-5);
}

.home-hero__background-r2i:nth-child(3) svg {
  fill: var(--color-purple-6);
}

.home-hero__background-r2i:nth-child(4) {
  background: var(--color-yellow-5);
}

.home-hero__background-r2i:nth-child(4) svg {
  fill: var(--color-yellow-6);
}

.home-hero__background-r2i:nth-child(5) {
  background: var(--color-primary-5);
}

.home-hero__background-r2i:nth-child(5) svg {
  fill: var(--color-primary-6);
}

.home-content {
  margin-bottom: 64px;
  padding: 0;
}

@media (width >= 992px) {
  .home-content {
    margin-bottom: 120px;
    padding: 0;
  }
}

.home-content header {
  margin-bottom: 24px;
}

@media (width >= 992px) {
  .home-content header {
    margin-bottom: 40px;
  }
}

.home-content header > h2 {
  margin-bottom: 8px;
}

@media (width >= 992px) {
  .home-content header > h2 {
    max-width: 588px;
    margin-bottom: 20px;
  }
}

.home-content header p:last-child {
  margin-bottom: 0;
}

.home-content p {
  color: var(--color-neutral-8);
}

.home-tagsection {
  margin-bottom: 64px;
  padding: 0;
}

@media (width >= 992px) {
  .home-tagsection {
    margin-bottom: 120px;
  }
}

@media (width >= 768px) {
  .home-tagsection header {
    max-width: 500px;
  }
}

.home-tagsection header p {
  max-width: 100% !important;
  margin-bottom: 0 !important;
}

.home-tagsection p {
  margin-bottom: 24px;
}

@media (width >= 768px) {
  .home-tagsection p {
    max-width: 350px;
  }
}

@media (width >= 992px) {
  .home-tagsection p {
    max-width: 440px;
    margin-bottom: 40px;
  }
}

.home-tagsection .page-wrapper {
  background: var(--color-cream-0);
  border: 1px solid #0000000d;
  border-radius: 16px;
  padding: 16px;
}

@media (width >= 768px) {
  .home-tagsection .page-wrapper {
    background-image: -webkit-image-set(url("tag-section.6a0cf63a.png") 1x, url("tag-section@2x.64bae9f4.png") 2x, url("tag-section@3x.2434503b.png") 3x);
    background-image: image-set("tag-section.6a0cf63a.png" 1x, "tag-section@2x.64bae9f4.png" 2x, "tag-section@3x.2434503b.png" 3x);
    background-position: right 0 bottom 0;
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 32px;
    padding: 64px 32px;
  }
}

@media (width >= 992px) {
  .home-tagsection .page-wrapper {
    padding: 80px 64px;
  }
}

.home-manage .page-wrapper {
  background: var(--color-primary-0);
  border: 1px solid #0000000d;
  border-radius: 16px;
  padding: 16px;
}

@media (width >= 768px) {
  .home-manage .page-wrapper {
    background-image: -webkit-image-set(url("manage-section-mobile.01ebe685.png") 1x, url("manage-section-mobile@2x.ff50c9e3.png") 2x, url("manage-section-mobile@3x.86d56726.png") 3x);
    background-image: image-set("manage-section-mobile.01ebe685.png" 1x, "manage-section-mobile@2x.ff50c9e3.png" 2x, "manage-section-mobile@3x.86d56726.png" 3x);
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: 385px;
    border-radius: 32px;
    flex-direction: column;
    align-items: flex-end;
    padding: 64px 32px;
    display: flex;
  }
}

@media (width >= 992px) {
  .home-manage .page-wrapper {
    background-image: -webkit-image-set(url("manage-section.2242ba06.png") 1x, url("manage-section@2x.3abe817f.png") 2x, url("manage-section@3x.341872f4.png") 3x);
    background-image: image-set("manage-section.2242ba06.png" 1x, "manage-section@2x.3abe817f.png" 2x, "manage-section@3x.341872f4.png" 3x);
    background-position: 40px 46px;
    background-repeat: no-repeat;
    background-size: 564px;
    padding: 80px 64px;
  }
}

@media (width >= 768px) {
  .home-manage .page-wrapper > header, .home-manage .page-wrapper p {
    max-width: 400px;
  }
}

@media (width >= 992px) {
  .home-manage .page-wrapper > header, .home-manage .page-wrapper p {
    max-width: 464px;
  }
}

.home-cases .page-wrapper {
  padding: 0 16px;
}

@media (width >= 992px) {
  .home-cases .page-wrapper {
    padding: 0 64px;
  }
}

.home-cases__list {
  grid-template-columns: repeat(1, 1fr);
  row-gap: 16px;
  display: grid;
}

@media (width >= 992px) {
  .home-cases__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

.home-cases__item {
  background-color: var(--color-primary-0);
  border-radius: 24px;
  align-items: flex-start;
  padding: 16px;
  display: inline-flex;
}

.home-cases__item > div:first-of-type {
  margin-right: 16px;
}

.home-cases__item img {
  width: 24px;
}

@media (width >= 768px) {
  .home-cases__item img {
    width: 32px;
  }
}

.home-cases__item h3 {
  margin-bottom: 8px;
}

.home-cases__item p {
  margin: 0;
}

.home-getstarted {
  background-color: var(--color-cream-0);
  margin-bottom: 0;
}

.home-getstarted .page-wrapper {
  padding: 64px 16px;
}

@media (width >= 992px) {
  .home-getstarted .page-wrapper {
    padding: 120px 64px;
  }
}

.home-getstarted header {
  margin-bottom: 40px;
}

@media (width >= 992px) {
  .home-getstarted header {
    margin-bottom: 80px;
  }
}

.home-getstarted__steps {
  grid-template-columns: repeat(1, 1fr);
  row-gap: 32px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  position: relative;
}

@media (width >= 768px) {
  .home-getstarted__steps {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 24px;
  }
}

@media (width >= 992px) {
  .home-getstarted__steps {
    grid-template-columns: repeat(4, 1fr);
    gap: 0 24px;
  }

  .home-getstarted__steps:before {
    content: "";
    border-bottom: 1px dashed var(--color-cream-2);
    width: 80%;
    position: absolute;
    top: 60px;
    left: 10%;
  }
}

.home-getstarted__step {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.home-getstarted__step-icon {
  background-color: var(--color-cream-1);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 82px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

@media (width >= 992px) {
  .home-getstarted__step-icon {
    width: 114px;
    height: 114px;
    margin-bottom: 24px;
  }
}

.home-getstarted h3 {
  margin-bottom: 8px;
}

.home-getstarted__step-counter {
  color: var(--color-neutral-00);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-h6-fontsize);
  line-height: var(--typo-heading-h6-lineheight);
  font-weight: var(--typo-heading-h6-fontweight);
  background-color: var(--color-primary-5);
  text-align: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
}

.home-faq {
  background: var(--color-primary-0);
  margin: 0;
}

.home-faq .page-wrapper {
  padding: 64px 16px;
}

@media (width >= 992px) {
  .home-faq .page-wrapper {
    padding: 120px 64px;
  }
}

.home-faq .page-wrapper header {
  margin-bottom: 24px;
}

@media (width >= 992px) {
  .home-faq .page-wrapper header {
    margin-bottom: 40px;
  }
}

.home-faq .page-wrapper header h2 {
  margin-bottom: 8px;
}

@media (width >= 992px) {
  .home-faq .page-wrapper header h2 {
    margin-bottom: 20px;
  }
}

.home-faq .page-wrapper header p {
  color: var(--color-neutral-8);
  margin-bottom: 0;
}

.home-faq__toggle-list {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.home-faq__toggle {
  background: var(--color-neutral-00);
  border-bottom: 1px solid var(--color-neutral-1);
  border-radius: 8px;
  padding: 24px;
}

.home-faq__toggle--open .home-faq__toggle-body {
  display: block;
}

.home-faq__toggle--open .home-faq__toggle-icon {
  transform: rotate(180deg);
}

.home-faq__toggle-header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.home-faq__toggle-header h5 {
  font-weight: var(--font-secondary-weight-semibold);
  color: var(--color-primary-7);
  margin: 0;
}

.home-faq__toggle-body {
  display: none;
}

.home-faq__toggle-body p:last-child {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.80b3be6e.css.map */
