@import "../definitions/typography";

html {
  background-color: var(--background-html);
  scroll-behavior: smooth;
}

body {
  @include body(medium);
  background: var(--background-body);
}

h1 {
  @include heading(1);
}

h2 {
  @include heading(2);
}

h3 {
  @include heading(3);
}

h4 {
  @include heading(4);
}

h5 {
  @include heading(5);
}

h6 {
  @include heading(6);
}

p {
  @include body(medium);
}

a {
  color: var(--color-primary-5);
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-primary-8);
  }
}
