.home {
}

@keyframes home-background-r {
  0% {
    transform: translateY(0);
  }
  10% {
  }
  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes home-background-r2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
}

.home-hero {
  padding: 0;
  overflow-y: hidden;
  @include lg {
    padding: 0 0 major-scale(21);
  }

  .page-wrapper {
    padding: major-scale(4) major-scale(3) major-scale(8);
    position: relative;

    @include md {
      padding: major-scale(8) major-scale(5);
    }

    @include lg {
      padding: major-scale(8) major-scale(6);
    }
  }

  .page-header__nav {
    @include md {
      margin-bottom: major-scale(5);
    }

    @include lg {
      margin-bottom: major-scale(10);
    }

    .page-header__logo img {
      height: 24px;
    }
  }

  h1 {
    margin-bottom: major-scale(2);

    @include sm {
      width: 310px;
    }

    @include md {
      width: 552px;
    }

    @include lg {
      width: auto;
      width: 442px;
      margin-bottom: major-scale(3);
    }
  }

  p {
    margin-bottom: major-scale(3);
    max-width: 390px;

    @include md {
      width: 480px;
    }

    @include lg {
      width: auto;
      max-width: 468px;
      margin-bottom: major-scale(5);
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: major-scale(1);

    @include md {
      gap: major-scale(2);
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;

    @include lg {
      display: block;
    }

    > div {
      display: flex;
      align-items: flex-start;
      gap: major-scale(4);
      overflow-y: hidden;
    }

    &-r,
    &-r2 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: major-scale(4);

      > div {
        border-radius: border-radius(16);
      }

      svg {
        width: 32px;
        fill: black;
        margin: major-scale(4);
      }
    }

    &-r {
      animation: home-background-r 20s linear infinite;
    }

    &-r2 {
      animation: home-background-r2 40s linear infinite;
    }

    &-ri {
      background: var(--color-primary-5);
      width: 298px;
      height: 466px;

      svg {
        fill: var(--color-primary-6);
      }

      &:nth-child(2) {
        background: var(--color-yellow-5);

        svg {
          fill: var(--color-yellow-6);
        }
      }

      &:nth-child(3) {
        background: var(--color-green-5);

        svg {
          fill: var(--color-green-6);
        }
      }

      &:nth-child(4) {
        background: var(--color-cyan-5);

        svg {
          fill: var(--color-cyan-6);
        }
      }

      &:nth-child(5) {
        background: var(--color-purple-5);

        svg {
          fill: var(--color-purple-6);
        }
      }
    }

    &-r2i {
      width: 298px;
      height: 308px;
      background: var(--color-green-5);

      svg {
        fill: var(--color-green-6);
      }

      &:nth-child(2) {
        background: var(--color-cyan-5);

        svg {
          fill: var(--color-cyan-6);
        }
      }

      &:nth-child(3) {
        background: var(--color-purple-5);

        svg {
          fill: var(--color-purple-6);
        }
      }

      &:nth-child(4) {
        background: var(--color-yellow-5);

        svg {
          fill: var(--color-yellow-6);
        }
      }

      &:nth-child(5) {
        background: var(--color-primary-5);

        svg {
          fill: var(--color-primary-6);
        }
      }
    }
  }
}

.home-content {
  padding: 0;
  margin-bottom: major-scale(8);

  @include lg {
    padding: 0;
    margin-bottom: major-scale(15);
  }

  header {
    margin-bottom: major-scale(3);

    @include lg {
      margin-bottom: major-scale(5);
    }

    > h2 {
      margin-bottom: major-scale(1);

      @include lg {
        max-width: 588px;
        margin-bottom: major-scale(2.5);
      }
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    color: var(--color-neutral-8);
  }
}

.home-tagsection {
  padding: major-scale(0);
  margin-bottom: major-scale(8);

  @include lg {
    margin-bottom: major-scale(15);
  }

  header {
    @include md {
      max-width: 500px;
    }

    p {
      max-width: 100% !important;
      margin-bottom: 0 !important;
    }
  }

  p {
    margin-bottom: major-scale(3);

    @include md {
      max-width: 350px;
    }

    @include lg {
      max-width: 440px;
      margin-bottom: major-scale(5);
    }
  }

  .page-wrapper {
    background: var(--color-cream-0);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: border-radius(8);
    padding: major-scale(2);

    @include md {
      background-image: image-set(
        url("/assets/images/tag-section.png") 1x,
        url("/assets/images/tag-section@2x.png") 2x,
        url("/assets/images/tag-section@3x.png") 3x
      );
      background-repeat: no-repeat;
      background-position: bottom 0 right 0;
      background-size: 50%;
      border-radius: border-radius(16);
      padding: major-scale(8) major-scale(4);
    }

    @include lg {
      padding: major-scale(10) major-scale(8);
    }
  }
}

.home-manage {
  .page-wrapper {
    background: var(--color-primary-0);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: border-radius(8);
    padding: major-scale(2);

    @include md {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      background-image: image-set(
        url("/assets/images/manage-section-mobile.png") 1x,
        url("/assets/images/manage-section-mobile@2x.png") 2x,
        url("/assets/images/manage-section-mobile@3x.png") 3x
      );
      background-repeat: no-repeat;
      background-position: bottom 0 left 0;
      background-size: 385px;
      border-radius: border-radius(16);
      padding: major-scale(8) major-scale(4);
    }

    @include lg {
      background-image: image-set(
        url("/assets/images/manage-section.png") 1x,
        url("/assets/images/manage-section@2x.png") 2x,
        url("/assets/images/manage-section@3x.png") 3x
      );
      background-repeat: no-repeat;
      background-position: top 46px left 40px;
      background-size: 564px;
      padding: major-scale(10) major-scale(8);
    }

    > header,
    p {
      @include md {
        max-width: 400px;
      }

      @include lg {
        max-width: 464px;
      }
    }
  }
}

.home-cases {
  .page-wrapper {
    padding: 0 major-scale(2);

    @include lg {
      padding: 0 major-scale(8);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: major-scale(2);

    @include lg {
      grid-template-columns: repeat(2, 1fr);
      row-gap: major-scale(3);
      column-gap: major-scale(3);
    }
  }

  &__item {
    align-items: flex-start;
    background-color: var(--color-primary-0);
    border-radius: border-radius(12);
    display: inline-flex;
    padding: major-scale(2);

    > div:first-of-type {
      margin-right: major-scale(2);
    }

    img {
      width: 24px;

      @include md {
        width: 32px;
      }
    }

    h3 {
      margin-bottom: major-scale(1);
    }

    p {
      margin: 0;
    }
  }
}

.home-getstarted {
  margin-bottom: 0;
  background-color: var(--color-cream-0);

  .page-wrapper {
    padding: major-scale(8) major-scale(2);

    @include lg {
      padding: major-scale(15) major-scale(8);
    }
  }

  header {
    margin-bottom: major-scale(5);

    @include lg {
      margin-bottom: major-scale(10);
    }
  }

  &__steps {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: major-scale(4);
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;

    @include md {
      grid-template-columns: repeat(2, 1fr);
      row-gap: major-scale(4);
      column-gap: major-scale(3);
    }

    @include lg {
      grid-template-columns: repeat(4, 1fr);
      row-gap: major-scale(0);
      column-gap: major-scale(3);
    }

    &:before {
      @include lg {
        content: "";
        width: 80%;
        left: 10%;
        border-bottom: 1px dashed var(--color-cream-2);
        position: absolute;
        top: 60px;
      }
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }

  &__step-icon {
    background-color: var(--color-cream-1);
    border-radius: 100%;
    position: relative;
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: major-scale(2);

    @include lg {
      width: 114px;
      height: 114px;
      margin-bottom: major-scale(3);
    }
  }

  h3 {
    margin-bottom: major-scale(1);
  }

  &__step-counter {
    @include heading(h6);
    background-color: var(--color-primary-5);
    border-radius: 100%;
    color: var(--color-neutral-00);
    text-align: center;
    display: block;
    line-height: 40px;
    height: 40px;
    width: 40px;
    position: absolute;
    top: -10px;
    left: -10px;
  }
}

.home-faq {
  background: var(--color-primary-0);
  margin: 0;

  .page-wrapper {
    padding: major-scale(8) major-scale(2);

    @include lg {
      padding: major-scale(15) major-scale(8);
    }

    header {
      margin-bottom: major-scale(3);

      @include lg {
        margin-bottom: major-scale(5);
      }

      h2 {
        margin-bottom: major-scale(1);

        @include lg {
          margin-bottom: major-scale(2.5);
        }
      }

      p {
        color: var(--color-neutral-8);
        margin-bottom: 0;
      }
    }
  }

  &__toggle-list {
    display: flex;
    flex-direction: column;
    gap: major-scale(1);
  }

  &__toggle {
    background: var(--color-neutral-00);
    border-bottom: 1px solid var(--color-neutral-1);
    padding: major-scale(3);
    border-radius: border-radius(4);

    &--open {
      .home-faq__toggle-body {
        display: block;
      }

      .home-faq__toggle-icon {
        transform: rotate(180deg);
      }
    }

    &-header {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;

      h5 {
        margin: 0;
        font-weight: var(--font-secondary-weight-semibold);
        color: var(--color-primary-7);
      }
    }

    &-body {
      display: none;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
