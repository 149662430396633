@import "./measurements.scss";

:root {
  // Colors
  --btn-primary-idle-background: var(--color-primary-5);
  --btn-primary-idle-border: var(--color-primary-5);
  --btn-primary-idle-font: var(--color-neutral-00);
  --btn-primary-hover-background: var(--color-primary-6);
  --btn-primary-hover-border: var(--color-primary-6);
  --btn-primary-hover-font: var(--color-neutral-00);
  --btn-primary-active-background: var(--color-primary-7);
  --btn-primary-active-border: var(--color-primary-7);
  --btn-primary-active-font: var(--color-primary-1);
  --btn-primary-loading-background: var(--color-primary-5);
  --btn-primary-loading-border: var(--color-primary-5);
  --btn-primary-loading-font: var(--color-neutral-00);
  --btn-primary-disabled-background: var(--color-neutral-3);
  --btn-primary-disabled-border: var(--color-neutral-3);
  --btn-primary-disabled-font: var(--color-neutral-1);
  --btn-secondary-idle-background: var(--color-primary-1);
  --btn-secondary-idle-border: var(--color-primary-1);
  --btn-secondary-idle-font: var(--color-primary-7);
  --btn-secondary-hover-background: var(--color-primary-2);
  --btn-secondary-hover-border: var(--color-primary-2);
  --btn-secondary-hover-font: var(--color-primary-7);
  --btn-secondary-active-background: var(--color-primary-3);
  --btn-secondary-active-border: var(--color-primary-3);
  --btn-secondary-active-font: var(--color-primary-8);
  --btn-secondary-loading-background: var(--color-primary-1);
  --btn-secondary-loading-border: var(--color-primary-1);
  --btn-secondary-loading-font: var(--color-primary-7);
  --btn-secondary-disabled-background: var(--color-neutral-2);
  --btn-secondary-disabled-border: var(--color-neutral-2);
  --btn-secondary-disabled-font: var(--color-neutral-4);
  --btn-outline-idle-background: transparent;
  --btn-outline-idle-border: var(--color-primary-5);
  --btn-outline-idle-font: var(--color-primary-5);
  --btn-outline-hover-background: var(--color-primary-1);
  --btn-outline-hover-border: var(--color-primary-5);
  --btn-outline-hover-font: var(--color-primary-5);
  --btn-outline-active-background: var(--color-primary-2);
  --btn-outline-active-border: var(--color-primary-6);
  --btn-outline-active-font: var(--color-primary-6);
  --btn-outline-loading-background: var(--color-neutral-00);
  --btn-outline-loading-border: var(--color-primary-5);
  --btn-outline-loading-font: var(--color-primary-5);
  --btn-outline-disabled-background: var(--color-neutral-00);
  --btn-outline-disabled-border: var(--color-neutral-3);
  --btn-outline-disabled-font: var(--color-neutral-3);
  --btn-danger-idle-background: var(--color-danger-5);
  --btn-danger-idle-border: var(--color-danger-5);
  --btn-danger-idle-font: var(--color-neutral-00);
  --btn-danger-hover-background: var(--color-danger-6);
  --btn-danger-hover-border: var(--color-danger-6);
  --btn-danger-hover-font: var(--color-neutral-00);
  --btn-danger-active-background: var(--color-danger-7);
  --btn-danger-active-border: var(--color-danger-7);
  --btn-danger-active-font: var(--color-danger-1);
  --btn-danger-loading-background: var(--color-danger-5);
  --btn-danger-loading-border: var(--color-danger-5);
  --btn-danger-loading-font: var(--color-neutral-00);
  --btn-danger-disabled-background: var(--color-neutral-3);
  --btn-danger-disabled-border: var(--color-neutral-3);
  --btn-danger-disabled-font: var(--color-neutral-1);

  //   Measurements
  --btn-border-radius: 8px;

  --btn-icon-sm: 16px;
  --btn-icon-md: 24px;
  --btn-icon-lg: 24px;
  --btn-icon-margin: 8px;

  --btn-padding-sm: 10px 16px;
  --btn-padding-md: 16px 20px;
  --btn-padding-lg: 20px 24px;
}

$styles: primary, secondary, outline, danger;
$states: idle, hover, active, loading, disabled;
$variations: sm, md, lg;

@mixin btn-style-state-map($style, $state) {
  background: var(--btn-#{$style}-#{$state}-background);
  border-color: var(--btn-#{$style}-#{$state}-border);
  color: var(--btn-#{$style}-#{$state}-font);
}

.btn {
  background: transparent;
  border-radius: var(--btn-border-radius);
  border: 1px solid;
  display: inline-block;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: center;

  @each $variation in $variations {
    &--#{$variation} {
      padding: var(--btn-padding-#{$variation});
      font-size: var(--typo-button-#{$variation}-fontsize);
      line-height: var(--typo-button-#{$variation}-lineheight);
      font-weight: var(--typo-button-#{$variation}-fontweight);

      &-on-sm {
        @include sm {
          padding: var(--btn-padding-#{$variation});
          font-size: var(--typo-button-#{$variation}-fontsize);
          line-height: var(--typo-button-#{$variation}-lineheight);
          font-weight: var(--typo-button-#{$variation}-fontweight);
        }
      }

      &-on-md {
        @include md {
          padding: var(--btn-padding-#{$variation});
          font-size: var(--typo-button-#{$variation}-fontsize);
          line-height: var(--typo-button-#{$variation}-lineheight);
          font-weight: var(--typo-button-#{$variation}-fontweight);
        }
      }

      &-on-lg {
        @include lg {
          padding: var(--btn-padding-#{$variation});
          font-size: var(--typo-button-#{$variation}-fontsize);
          line-height: var(--typo-button-#{$variation}-lineheight);
          font-weight: var(--typo-button-#{$variation}-fontweight);
        }
      }
    }
  }

  &--full {
    width: 100%;

    &-on-sm {
      @include sm {
        width: 100%;
      }
    }

    &-on-md {
      @include md {
        width: 100%;
      }
    }

    &-on-lg {
      @include lg {
        width: 100%;
      }
    }
  }

  &--fit {
    width: auto;

    &-on-sm {
      @include sm {
        width: auto;
      }
    }

    &-on-md {
      @include md {
        width: auto;
      }
    }

    &-on-lg {
      @include lg {
        width: auto;
      }
    }
  }

  img {
    display: inline;
    vertical-align: text-top;
  }
}

.btn-icon {
  @extend .btn;

  svg {
    vertical-align: middle;
    width: var(--btn-icon-sm);
    height: var(--btn-icon-sm);
  }

  @each $variation in $variations {
    &.btn--#{$variation},
    &.btn--#{$variation}-on-sm,
    &.btn--#{$variation}-on-md,
    &.btn--#{$variation}-on-lg {
      svg {
        width: var(--btn-icon-#{$variation});
        height: var(--btn-icon-#{$variation});
      }
    }
  }
}

@each $style in $styles {
  .btn-#{$style} {
    @include btn-style-state-map($style, idle);

    &:hover {
      @include btn-style-state-map($style, hover);
    }

    &:active {
      @include btn-style-state-map($style, active);
    }

    &:disabled {
      @include btn-style-state-map($style, disabled);
    }

    &.loading {
      @include btn-style-state-map($style, loading);
    }
  }
}
