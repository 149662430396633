.page {
}

.page-header {
  background-color: var(--background-html);
  padding-top: major-scale(1);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: major-scale(1);
  }
}

.page-header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: major-scale(3);
}

.page-header__mmenu {
  display: inline-flex;
  position: relative;
  flex-direction: row-reverse;
  height: 42px;

  @include lg {
    display: none;
  }

  ul {
    display: none;
    margin-right: major-scale(3);

    li {
      margin-right: major-scale(1.5);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  > img,
  &-hack {
    width: 24px;
  }

  &-hack {
    display: block;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    height: 100%;

    &:checked ~ ul {
      display: inline-flex;
    }
  }
}

.page-header__menu {
  display: none;

  @include lg {
    display: block;
  }
}

.page-wrapper {
  margin: 0 auto;
  padding: major-scale(3) 0;

  @include sm {
    padding: major-scale(3);
  }

  @include md {
    padding: major-scale(3) major-scale(5);
  }

  @include lg {
    max-width: $screen-xl-min;
    padding: major-scale(3) major-scale(6);
  }

  @include xl {
    max-width: $screen-xl-min;
    padding: major-scale(3) major-scale(10);
  }
}

.page-content {
  p {
    margin-bottom: major-scale(2);
  }
}

.page-footer {
  background: var(--color-primary-8);

  a:not(.btn) {
    color: var(--color-primary-2);
  }
}

.page-footer__cta {
  background: var(--color-primary-1);
  color: var(--color-neutral-8) !important;
  padding: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: major-scale(1.5);
  }

  p {
    margin-bottom: major-scale(3);
  }

  .btn {
    @include md {
      margin-left: major-scale(5);
    }
  }

  .page-wrapper {
    padding: major-scale(10) major-scale(2);

    @include md {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    @include lg {
      padding: major-scale(10) major-scale(6);
    }
  }
}

.page-footer__links {
  padding: major-scale(6) 0;
  text-align: center;

  @include lg {
    padding: major-scale(10) 0;
  }

  img {
    display: inline-block;
    margin-bottom: major-scale(5);

    @include md {
      margin-bottom: 0;
    }
  }

  .page-wrapper {
    @include md {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}

.ul-h {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    margin-right: major-scale(6);

    &:last-child {
      margin-right: 0;
    }
  }
}

.page-legal {
  .page-content {
    h2,
    h3 {
      margin-bottom: major-scale(1);
    }
  }
}
