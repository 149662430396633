:root {
  /* Colors */

  /* Primary color */
  --color-primary-0: #fdf5f9;
  --color-primary-1: #f7dae6;
  --color-primary-2: #f1bdd2;
  --color-primary-3: #ea90b2;
  --color-primary-4: #e24d81;
  --color-primary-5: #de3362;
  --color-primary-6: #b2274f;
  --color-primary-7: #8e1e3f;
  --color-primary-8: #62132b;
  --color-primary-9: #3e0a1c;
  /* Green */
  --color-green-0: #e5fff3;
  --color-green-1: #ccffe6;
  --color-green-2: #99ffc7;
  --color-green-3: #66ffab;
  --color-green-4: #33ff92;
  --color-green-5: #00e676;
  --color-green-6: #00c263;
  --color-green-7: #00994e;
  --color-green-8: #006634;
  --color-green-9: #004d27;
  /* Cyan */
  --color-cyan-0: #ebfdff;
  --color-cyan-1: #ccfaff;
  --color-cyan-2: #99f5ff;
  --color-cyan-3: #66efff;
  --color-cyan-4: #33eaff;
  --color-cyan-5: #00dcf5;
  --color-cyan-6: #00b7db;
  --color-cyan-7: #0095b2;
  --color-cyan-8: #00738a;
  --color-cyan-9: #004452;
  /* Red */
  --color-red-0: #fceeeb;
  --color-red-1: #f8d5ce;
  --color-red-2: #f2ab9d;
  --color-red-3: #ee826d;
  --color-red-4: #ec5b41;
  --color-red-5: #e13c23;
  --color-red-6: #bc301b;
  --color-red-7: #8c2312;
  --color-red-8: #6f1c0c;
  --color-red-9: #501309;
  /* Yellow */
  --color-yellow-0: #fff9e5;
  --color-yellow-1: #ffedb3;
  --color-yellow-2: #ffe799;
  --color-yellow-3: #ffdc66;
  --color-yellow-4: #ffd342;
  --color-yellow-5: #ffc400;
  --color-yellow-6: #cc9d00;
  --color-yellow-7: #a87e00;
  --color-yellow-8: #755c00;
  --color-yellow-9: #574300;
  /* Purple */
  --color-purple-0: #faf0ff;
  --color-purple-1: #dfc2ff;
  --color-purple-2: #ca99ff;
  --color-purple-3: #bd7aff;
  --color-purple-4: #b35cff;
  --color-purple-5: #aa00ff;
  --color-purple-6: #8800cc;
  --color-purple-7: #660099;
  --color-purple-8: #4b0070;
  --color-purple-9: #3a0057;
  /* Cream */
  --color-cream-0: #fdfaf6;
  --color-cream-1: #f9efe2;
  --color-cream-2: #f1dcc0;
  --color-cream-3: #e1c199;
  --color-cream-4: #bc9c76;
  --color-cream-5: #ab8254;
  --color-cream-6: #896b43;
  --color-cream-7: #655034;
  --color-cream-8: #433523;
  --color-cream-9: #221b11;
  /* Danger */
  --color-danger-0: #fff0f0;
  --color-danger-1: #ffcccc;
  --color-danger-2: #ff8f8f;
  --color-danger-3: #ff5555;
  --color-danger-4: #ff2525;
  --color-danger-5: #ff0000;
  --color-danger-6: #c30000;
  --color-danger-7: #930000;
  --color-danger-8: #630000;
  --color-danger-9: #330000;
  /* Neutral */
  --color-neutral-0: #fafafa;
  --color-neutral-1: #eeedec;
  --color-neutral-2: #ceccca;
  --color-neutral-3: #b6b3af;
  --color-neutral-4: #9d9995;
  --color-neutral-5: #85807a;
  --color-neutral-6: #6f6b67;
  --color-neutral-7: #585451;
  --color-neutral-8: #42403d;
  --color-neutral-9: #1b1a18;
  --color-neutral-00: #ffffff;

  /* Global backgrounds */
  --background-body: var(--color-neutral-00);
  --background-html: #fff;

  /* Typography */

  /* Font family definition */
  --font-primary: "Space Grotesk";
  --font-secondary: "Inter";

  /* Font weight definition */
  --font-primary-weight-bold: 700;
  --font-primary-weight-semibold: 600;
  --font-primary-weight-medium: 500;
  --font-primary-weight-regular: 400;

  --font-secondary-weight-bold: 700;
  --font-secondary-weight-semibold: 600;
  --font-secondary-weight-medium: 500;
  --font-secondary-weight-regular: 400;

  /* Typography definition */

  /* Heading variation */
  /* display, h1, h2, h3, h4, h5, h6 */
  --font-family-heading: var(--font-primary);
  --font-color-heading: var(--color-primary-9);

  --typo-heading-display-fontsise: 96px;
  --typo-heading-display-lineheight: 112px;
  --typo-heading-display-fontweight: var(--font-primary-weight-bold);

  --typo-heading-h1-fontsize: 64px;
  --typo-heading-h1-lineheight: 72px;
  --typo-heading-h1-fontweight: var(--font-primary-weight-bold);

  --typo-heading-h2-fontsize: 48px;
  --typo-heading-h2-lineheight: 56px;
  --typo-heading-h2-fontweight: var(--font-primary-weight-bold);

  --typo-heading-h3-fontsize: 32px;
  --typo-heading-h3-lineheight: 40px;
  --typo-heading-h3-fontweight: var(--font-primary-weight-bold);

  --typo-heading-h4-fontsize: 24px;
  --typo-heading-h4-lineheight: 32px;
  --typo-heading-h4-fontweight: var(--font-primary-weight-bold);

  --typo-heading-h5-fontsize: 20px;
  --typo-heading-h5-lineheight: 24px;
  --typo-heading-h5-fontweight: var(--font-primary-weight-medium);

  --typo-heading-h6-fontsize: 16px;
  --typo-heading-h6-lineheight: 20px;
  --typo-heading-h6-fontweight: var(--font-primary-weight-medium);

  /* Body variation */
  /* large, medium, small */
  --font-family-body: var(--font-secondary);
  --font-color-body: var(--color-neutral-9);

  --typo-body-large-fontsize: 18px;
  --typo-body-large-lineheight: 28px;
  --typo-body-large-fontweight: var(--font-secondary-weight-regular);

  --typo-body-medium-fontsize: 16px;
  --typo-body-medium-lineheight: 24px;
  --typo-body-medium-fontweight: var(--font-secondary-weight-regular);

  --typo-body-small-fontsize: 14px;
  --typo-body-small-lineheight: 20px;
  --typo-body-small-fontweight: var(--font-secondary-weight-regular);

  /* Label variation */
  /* medium, small */
  --font-family-label: var(--font-secondary);
  --font-color-label: var(--color-neutral-9);

  --typo-label-medium-fontsize: 14px;
  --typo-label-medium-lineheight: 18px;
  --typo-label-medium-fontweight: var(--font-secondary-weight-medium);

  --typo-label-small-fontsize: 12px;
  --typo-label-small-lineheight: 14px;
  --typo-label-small-fontweight: var(--font-secondary-weight-medium);

  /* Caption variation */
  /* large, medium, small */
  --font-family-caption: var(--font-secondary);
  --font-color-caption: var(--color-neutral-9);

  --typo-caption-large-fontsize: 14px;
  --typo-caption-large-lineheight: 20px;
  --typo-caption-large-fontweight: var(--font-secondary-weight-regular);

  --typo-caption-medium-fontsize: 12px;
  --typo-caption-medium-lineheight: 16px;
  --typo-caption-medium-fontweight: var(--font-secondary-weight-regular);

  --typo-caption-small-fontsize: 11px;
  --typo-caption-small-lineheight: 14px;
  --typo-caption-small-fontweight: var(--font-secondary-weight-regular);

  /* Button variation */
  /* large, medium, small */
  --font-family-button: var(--font-secondary);
  --font-color-button: var(--color-neutral-9);

  --typo-button-lg-fontsize: 18px;
  --typo-button-lg-lineheight: 24px;
  --typo-button-lg-fontweight: var(--font-secondary-weight-semibold);

  --typo-button-md-fontsize: 16px;
  --typo-button-md-lineheight: 24px;
  --typo-button-md-fontweight: var(--font-secondary-weight-semibold);

  --typo-button-sm-fontsize: 14px;
  --typo-button-sm-lineheight: 20px;
  --typo-button-sm-fontweight: var(--font-secondary-weight-semibold);
}
