$major-scale: 8;
$minor-scale: 2;
$scale-rounds: 8;
$border-scale: 2;

@function major-scale($i) {
  $result: $i * $major-scale * 1px;
  @return $result;
}

@function minor-scale($i) {
  $result: $i * $minor-scale * 1px;
  @return $result;
}

@function border-radius($i) {
  $result: $i * $border-scale * 1px;
  @return $result;
}

@for $i from 0 through $scale-rounds {
  .mm-#{$i} {
    margin: $i * $major-scale * 1px !important;
  }

  .mt-#{$i} {
    margin-top: $i * $major-scale * 1px !important;
  }

  .mr-#{$i} {
    margin-right: $i * $major-scale * 1px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * $major-scale * 1px !important;
  }

  .ml-#{$i} {
    margin-left: $i * $major-scale * 1px !important;
  }

  .pp-#{$i} {
    padding: $i * $minor-scale * 1px;
  }

  .pt-#{$i} {
    padding-top: $i * $minor-scale * 1px;
  }

  .pr-#{$i} {
    padding-right: $i * $minor-scale * 1px;
  }

  .pb-#{$i} {
    padding-bottom: $i * $minor-scale * 1px;
  }

  .pl-#{$i} {
    padding-left: $i * $minor-scale * 1px;
  }
}
