// Heading variation definitions
$heading: h1, h2, h3, h4, h5, h6;

@mixin heading($variation) {
  color: var(--font-color-heading);
  font-family: var(--font-family-heading);
  font-size: var(--typo-heading-#{$variation}-fontsize);
  line-height: var(--typo-heading-#{$variation}-lineheight);
  font-weight: var(--typo-heading-#{$variation}-fontweight);
}

// Body variation definitions
$body: large, medium, small;

@mixin body($variation) {
  color: var(--font-color-body);
  font-family: var(--font-family-body);
  font-size: var(--typo-body-#{$variation}-fontsize);
  line-height: var(--typo-body-#{$variation}-lineheight);
  font-weight: var(--typo-body-#{$variation}-fontweight);
}

// Label variation definitions
$label: medium, small;

@mixin label($variation) {
  color: var(--font-color-label);
  font-family: var(--font-family-label);
  font-size: var(--typo-label-#{$variation}-fontsize);
  line-height: var(--typo-label-#{$variation}-lineheight);
  font-weight: var(--typo-label-#{$variation}-fontweight);
}

//  Caption variation definitions
$caption: large, medium, small;

@mixin caption($variation) {
  color: var(--font-color-caption);
  font-family: var(--font-family-caption);
  font-size: var(--typo-caption-#{$variation}-fontsize);
  line-height: var(--typo-caption-#{$variation}-lineheight);
  font-weight: var(--typo-caption-#{$variation}-fontweight);
}

//  Button variation definitions
$button: large, medium, small;

@mixin button($variation) {
  color: var(--font-color-button);
  font-family: var(--font-family-button);
  font-size: var(--typo-button-#{$variation}-fontsize);
  line-height: var(--typo-button-#{$variation}-lineheight);
  font-weight: var(--typo-button-#{$variation}-fontweight);
}

.typo-heading-h6 {
  @include heading(h6);

  &--on-sm {
    @include sm {
      @include heading(h6);
    }
  }

  &--on-md {
    @include md {
      @include heading(h6);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(h6);
    }
  }
}

.typo-heading-h5 {
  @include heading(h5);

  &--on-sm {
    @include sm {
      @include heading(h5);
    }
  }

  &--on-md {
    @include md {
      @include heading(h5);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(h5);
    }
  }
}

.typo-heading-h4 {
  @include heading(h4);

  &--on-sm {
    @include sm {
      @include heading(h4);
    }
  }

  &--on-md {
    @include md {
      @include heading(h4);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(h4);
    }
  }
}

.typo-heading-h3 {
  @include heading(h3);

  &--on-sm {
    @include sm {
      @include heading(h3);
    }
  }

  &--on-md {
    @include md {
      @include heading(h3);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(h3);
    }
  }
}

.typo-heading-h2 {
  @include heading(h2);

  &--on-sm {
    @include sm {
      @include heading(h2);
    }
  }

  &--on-md {
    @include md {
      @include heading(h2);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(h2);
    }
  }
}

.typo-heading-h1 {
  @include heading(h1);

  &--on-sm {
    @include sm {
      @include heading(h1);
    }
  }

  &--on-md {
    @include md {
      @include heading(h1);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(h1);
    }
  }
}

.typo-heading-display {
  @include heading(display);

  &--on-sm {
    @include sm {
      @include heading(display);
    }
  }

  &--on-md {
    @include md {
      @include heading(display);
    }
  }

  &--on-lg {
    @include lg {
      @include heading(display);
    }
  }
}

.typo-body-sm {
  @include body(small);

  &--on-sm {
    @include sm {
      @include body(small);
    }
  }

  &--on-md {
    @include md {
      @include body(small);
    }
  }

  &--on-lg {
    @include lg {
      @include body(small);
    }
  }
}

.typo-body-md {
  @include body(medium);

  &--on-sm {
    @include sm {
      @include body(medium);
    }
  }

  &--on-md {
    @include md {
      @include body(medium);
    }
  }

  &--on-lg {
    @include lg {
      @include body(medium);
    }
  }
}

.typo-body-lg {
  @include body(large);

  &--on-sm {
    @include sm {
      @include body(large);
    }
  }

  &--on-md {
    @include md {
      @include body(large);
    }
  }

  &--on-lg {
    @include lg {
      @include body(large);
    }
  }
}

.typo-label-sm {
  @include label(small);

  &--on-sm {
    @include sm {
      @include label(small);
    }
  }

  &--on-md {
    @include md {
      @include label(small);
    }
  }

  &--on-lg {
    @include lg {
      @include label(small);
    }
  }
}

.typo-label-md {
  @include label(medium);

  &--on-sm {
    @include sm {
      @include label(medium);
    }
  }

  &--on-md {
    @include md {
      @include label(medium);
    }
  }

  &--on-lg {
    @include lg {
      @include label(medium);
    }
  }
}

.typo-caption-sm {
  @include caption(small);

  &--on-sm {
    @include sm {
      @include caption(small);
    }
  }

  &--on-md {
    @include md {
      @include caption(small);
    }
  }

  &--on-lg {
    @include lg {
      @include caption(small);
    }
  }
}

.typo-caption-md {
  @include caption(medium);

  &--on-sm {
    @include sm {
      @include caption(medium);
    }
  }

  &--on-md {
    @include md {
      @include caption(medium);
    }
  }

  &--on-lg {
    @include lg {
      @include caption(medium);
    }
  }
}

.typo-caption-lg {
  @include caption(large);

  &--on-sm {
    @include sm {
      @include caption(large);
    }
  }

  &--on-md {
    @include md {
      @include caption(large);
    }
  }

  &--on-lg {
    @include lg {
      @include caption(large);
    }
  }
}

.typo-btn-sm {
  @include button(small);

  &--on-sm {
    @include sm {
      @include button(small);
    }
  }

  &--on-md {
    @include md {
      @include button(small);
    }
  }

  &--on-lg {
    @include lg {
      @include button(small);
    }
  }
}

.typo-btn-md {
  @include button(medium);

  &--on-sm {
    @include sm {
      @include button(medium);
    }
  }

  &--on-md {
    @include md {
      @include button(medium);
    }
  }

  &--on-lg {
    @include lg {
      @include button(medium);
    }
  }
}

.typo-btn-lg {
  @include button(large);

  &--on-sm {
    @include sm {
      @include button(large);
    }
  }

  &--on-md {
    @include md {
      @include button(large);
    }
  }

  &--on-lg {
    @include lg {
      @include button(large);
    }
  }
}

.typo-hightlight {
  color: var(--color-primary-5);
}
